<template>
  <v-container fluid
    v-if="openForm"
  >
    <v-row justify="center">
      <v-dialog
        v-model="openForm"
        max-width="800"
        persistent
        transition="dialog-bottom-transition"
      >
        <v-card class="py-5 px-10">
          <v-card-title class="pl-0 mb-5">
            <h2>{{ formTitle }}</h2>
          </v-card-title>

          <v-form ref="form">
            <!-- キャスト -->
            <v-row>
              <v-col cols="6" class="pb-0">
                <v-select
                  class="mx-2"
                  v-model="booking.cast_id"
                  label="キャスト"
                  :items="casts"
                  item-value="cast_id"
                  item-text="name"
                  item-color="primary"
                  :rules="[valiRules.required]"
                  @focus="castOnFocus"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-switch
                  v-model="booking.is_honshi"
                  inset
                  hide-details
                  label="本指名"
                ></v-switch>
              </v-col>
            </v-row>

            <v-row>
              <!-- デートピッカー -->
              <v-col :cols="courseValue.course_id === 0 ? 4 : 6"
                 class="pb-0"
              >
                <v-menu top
                  v-model="openPickerDate"
                  :close-on-content-click="false"
                  :nudge-left="60"
                  transition="scroll-y-reverse-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="pickerDate"
                      label="日付"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[valiRules.required]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="pickerDate"
                    locale="ja"
                    no-title
                    :day-format="date => numericDate(date)"
                    header-color="primary"
                    event-color="primary"
                    @input="openPickerDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <!-- 開始時刻 -->
              <v-col :cols="courseValue.course_id === 0 ? 4 : 6"
                class="pb-0"
              >
                <v-menu top
                  v-model="openPickerStart"
                  :close-on-content-click="false"
                  :nudge-left="60"
                  transition="scroll-y-reverse-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="pickerStart"
                      label="時刻"
                      prepend-icon="mdi-clock-start"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[valiRules.required]"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-model="pickerStart"
                    format="24hr"
                    :allowed-hours="allowedHours"
                    :allowed-minutes="allowedMins"
                    header-color="primary"
                    color="primary"
                    @click:minute="openPickerStart = false"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <!-- コース未定時の終了時刻 -->
              <v-col cols="4"
                v-if="courseValue.course_id === 0"
                class="pb-0"
              >
                <v-menu top
                  v-model="openPickerEnd"
                  :close-on-content-click="false"
                  :nudge-left="60"
                  transition="scroll-y-reverse-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="pickerEnd"
                      label="終了時刻"
                      prepend-icon="mdi-clock-end"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="courseValue.course_id === 0 && [valiRules.required]"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-model="pickerEnd"
                    format="24hr"
                    :allowed-hours="allowedHours"
                    :allowed-minutes="allowedMins"
                    header-color="primary"
                    color="primary"
                    @click:minute="openPickerEnd = false"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
            <!-- コースタイプ＆コース -->
            <v-row>
              <v-col cols="6"
                class="pb-0"
              >
                <v-select
                  class="mx-2"
                  v-model="courseTypeValue"
                  label="コースタイプ"
                  :items="courseTypes"
                  return-object
                  item-text="course_type"
                  item-color="primary"
                  required
                  :rules="[valiRules.requiredObj]"
                  @focus="courseTypeOnFocus"
                ></v-select>
              </v-col>
              <v-col cols="6"
                class="pb-0"
              >
                <v-select
                  class="mx-2"
                  v-model="courseValue"
                  label="コース"
                  :items="typeCourses"
                  return-object
                  item-text="course_name"
                  item-color="primary"
                  required
                  :rules="[valiRules.requiredObj]"
                  @focus="courseOnFocus"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4" class="pb-0">
                <v-text-field
                  v-model="booking.customer_id"
                  type="number"
                  min="1" max="9999"
                  label="会員番号"
                  hint="会員情報が補完されます"
                  persistent-hint
                  :rules="[valiRules.min1num]"
                  :loading="loadingCustomer"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pb-0">
                <v-text-field
                  v-model="booking.booker_name"
                  :disabled="booking.customer_id !== ''"
                  label="ご予約名"
                  required
                  counter="20"
                  :rules="[valiRules.required, valiRules.max20]"
                  :loading="loadingCustomer"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pb-0">
                <v-text-field
                  v-model="booking.booker_phone"
                  :disabled="booking.customer_id !== ''"
                  label="電話番号"
                  counter="20"
                  :required="booking.booking_type === '電話'"
                  :rules="booking.booking_type === '電話' ?
                    [valiRules.required, valiRules.phone, valiRules.max20] : [valiRules.phone, valiRules.max20]"
                  :loading="loadingCustomer"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="8">
                <v-text-field
                  class="py-0"
                  v-model="booking.place"
                  label="場所"
                  counter="30"
                  :rules="[valiRules.max30]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  class="mt-0 pt-0"
                  v-model="booking.sales_ad_id"
                  label="広告媒体"
                  :items="salesAds"
                  item-value="sales_ad_id"
                  item-text="ad_name"
                  item-color="primary"
                  hide-details
                  @focus="salesAdOnFocus"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-textarea
                  class="py-0"
                  v-model="booking.note"
                  filled
                  rows="2" no-resize
                  counter="150"
                  label="備考"
                  :rules="[valiRules.max150]"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row class="mt-0 align-center">
              <v-col cols="2">
                予約種別
              </v-col>
              <v-col cols="10" sm="10">
                <v-radio-group
                  class="mt-0 pt-0"
                  v-model="booking.booking_type"
                  mandatory row
                  hide-details
                >
                  <v-radio class="mr-7"
                    v-if="shopData.is_incall"
                    label="来店"
                    value="来店"
                  ></v-radio>
                  <v-radio class="mr-7"
                    label="電話"
                    value="電話"
                  ></v-radio>
                  <v-radio class="mr-7"
                    label="ダミー"
                    value="ダミー"
                  ></v-radio>
                  <v-radio class="mr-7"
                    label="ネット"
                    value="ネット"
                  ></v-radio>
                  <v-radio class="mr-7"
                    label="LINE"
                    value="LINE"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="2" class="d-flex">
                予約状況
              </v-col>
              <v-col cols="10">
                <v-radio-group
                  class="mt-0 pt-0"
                  v-model="booking.booking_status"
                  mandatory row
                  hide-details
                >
                  <v-radio class="mr-7"
                    label="確定"
                    value="確定"
                  ></v-radio>
                  <v-radio class="mr-7"
                    label="仮予約"
                    value="仮予約"
                  ></v-radio>
                  <v-radio class="mr-7"
                    label="キャンセル"
                    value="キャンセル"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                depressed
                :disabled="!isFormChanged"
                color="primary"
                @click="formSubmitted"
              >
                {{ formButton }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="close"
              >
                閉じる
              </v-btn>
            </v-card-actions>
          </v-form>

          <!-- ローダー -->
          <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute top
          color="primary"
          ></v-progress-linear>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

  </v-container>
</template>

<script>
import moment from 'moment'
import $literals from '@/literals.js'
import { ApiTool, BizHour, CheckTokenError, ConvertPhone, ValidationRules } from '@/module.js'

export default {
  components: {
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    },
    casts: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      booking: {},
      courseTypes: [],
      courses: [],
      salesAds: [],
      openPickerDate: false,
      openPickerStart: false,
      openPickerEnd: false,
      allowedHours: [],
      allowedMins: [],
      pickerDate: '',
      pickerStart: '',
      pickerEnd: '',
      courseTypeValue: {},
      courseValue: {},
      timerId: null,
      openForm: false,
      isFormChanged: false,
      formType: '',
      comeBack: {},
      submitCallback: null,
      loading: false,
      loadingCustomer: false,
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
      bizHour: new BizHour(this.shopData),
    }
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
    formTitle() {
      return this.formType === 'create' ? '新規予約登録' : '予約情報更新'
    },
    formButton() {
      return this.formType === 'create' ? '登録' : '更新'
    },
    isCreate() {
      return this.formType === 'create' ? true : false
    },
    numericDate() {
      return date => moment(date).format('D')
    },
    typeCourses() {
      return this.courses.filter( course => course.course_type_id === this.courseTypeValue.course_type_id )
    },
    selectedCast() {
      return this.casts.find( cast => cast.cast_id === this.booking.cast_id )
    }
  },

  watch: {
    booking: {
      handler: function(now, old) {
        if (Object.keys(old).length && now.booking_id === old.booking_id) {
          this.isFormChanged = true
        }
      },
      deep: true
    },
    courseTypeValue: {
      handler: function(now, old) {
        if (!Object.keys(old).length) return
        this.courseValue = {}
      },
      deep: true
    },
    openForm: function(now, old) {
      if (now !== old) this.isFormChanged = false
    },
    //会員番号入力後に名前を自動入力
    'booking.customer_id': function(now) {
      if (this.timerId) clearTimeout(this.timerId)
      if (!now || now < 1) return

      this.loadingCustomer = true
      const vm = this
      this.timerId = setTimeout(function() {
        //1秒待って顧客情報取得しに
        vm.adminApi.getReqWithAuth('customer/' + now + '/')
        .then(customerData => {
          vm.booking.booker_name = customerData.name || '該当会員無し'
          vm.booking.booker_phone = customerData.phone || ''
          vm.booking.customer_id = customerData.customer_id || ''
          vm.loadingCustomer = false

          if (customerData.id === 0) alert($literals.MESSAGE.validationCustomerNA)
        })
        .catch(error => CheckTokenError(error))

        vm.timerId = null
      }, 1000)
    },
  },

  created() {
    this.adminApi.setToken(this.serverToken)

    this.allowedHours = this.bizHour.getBizHourArray()
    this.allowedMins = this.bizHour.getBizMinArray()

    //フォームに必要なデータを取得
    Promise.all([
      this.adminApi.getReqWithAuth('course-type/').then( results => {
        if (!results || !results.length) return
        this.courseTypes = [...results]
      }),
      this.adminApi.getReqWithAuth('course/').then( results => {
        if (!results || !results.length) return
        this.courses = [...results]
      }),
      this.adminApi.getReqWithAuth('sales-ad/').then( results => {
        if (!results || !results.length) return
        this.salesAds = [...results]
      }),
    ])
    .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
  },

  methods: {
    open(handdownData) {
      this.formType = handdownData.formType
      this.submitCallback = handdownData.submitCallback
      this.comeBack = handdownData.comeBack

      if (this.isCreate) {
        this.pickerDate = moment(new Date()).format('YYYY-MM-DD')
        this.courseTypeValue = this.courseTypes.length ? this.courseTypes[0] : {}
        this.booking = {
          booking_id: '',
          customer_id: '',
          cast_id: '',
          booking_status: '確定',
          booking_type: '電話',
          start_at: '',
          end_at: '',
          booker_name: '',
          booker_phone: '',
          sales_ad_id: 0,
          is_honshi: false,
          place: '',
          note: '',
        }
      } else {
        this.booking = { ...handdownData.updateData }
        if (this.booking.customer_id == 0) this.booking.customer_id = ''

        this.courseValue = {...this.courses.find( course => course.course_id === this.booking.course_id )}
        this.courseTypeValue = this.courseTypes.find( type => type.course_type_id === this.courseValue.course_type_id )

        this.pickerDate = moment(this.booking.start_at).format('YYYY-MM-DD')
        this.pickerStart = moment(this.booking.start_at).format('HH:mm')
        this.pickerEnd = moment(this.booking.end_at).format('HH:mm')
      }
      this.openForm = true
    },

    close() {
      this.courseValue = {}
      this.courseTypeValue = {}
      this.pickerDate = ''
      this.pickerStart = ''
      this.pickerEnd = ''
      this.openPickerDate = false
      this.openPickerStart = false
      this.openPickerEnd = false
      this.submitCallback = null
      this.loading = false
      this.isFormChanged = false
      this.openForm = false
    },

    castOnFocus() {
      if (!this.casts.length) {
        this.snackbar = {...{ color:'info', message: '「キャスト管理」よりキャストを登録してください', open: true }}
      }
    },

    courseTypeOnFocus() {
      if (!this.courseTypes.length) {
        this.snackbar = {...{ color:'info', message: '「HP基本設定」→「コースと料金」よりコースタイプを登録してください', open: true }}
      }
    },

    courseOnFocus() {
      if (!this.typeCourses.length) {
        this.snackbar = {...{ color:'info', message: '「HP基本設定」→「コースと料金」よりコースを登録してください', open: true }}
      }
    },

    salesAdOnFocus() {
      if (!this.salesAds.length) {
        this.snackbar = {...{ color:'info', message: '「店舗基本設定」→「利用広告媒体」より広告媒体を登録してください', open: true }}
      }
    },

    formSubmitted() {
      if (!this.$refs['form'].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      //時刻の設定
      const start_at = moment(this.pickerDate + ' ' + this.pickerStart)
      const start_hh = parseInt(this.pickerStart.split(':')[0])
      const end_hh = parseInt(this.pickerEnd.split(':')[0])

      //日またぎ営業店考慮（24時以降なら1日追加）
      if (this.bizHour.closingHourNum <= this.bizHour.openingHourNum && start_hh < this.bizHour.openingHourNum) {
        start_at.add(1, 'd')
      }

      //コース未定で終了時刻入力の場合
      let end_at
      if (this.courseValue.course_id === 0) {
        end_at = moment(this.pickerDate + ' ' + this.pickerEnd)

        if (this.bizHour.closingHourNum <= this.bizHour.openingHourNum && end_hh < this.bizHour.openingHourNum) {
          end_at.add(1, 'd')
        }
      } else {
        end_at = start_at.clone().add(this.courseValue.course_mins, 'm')
      }

      if (start_at.isAfter(end_at)) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationStartEndTime, open: true}}
        return
      }

      this.loading = true
      this.booking.cast_name = this.selectedCast.name
      this.booking.start_at = start_at.format()
      this.booking.end_at = end_at.format()
      this.booking.course_id = this.courseValue.course_id
      this.booking.course_name = this.courseValue.course_name

      const formData = new FormData()
      formData.append('id', this.booking.booking_id)
      formData.append('shop_id', this.shopData.shop_id)
      formData.append('customer_id', this.booking.customer_id || 0)
      formData.append('cast_id', this.booking.cast_id)
      formData.append('booking_type', this.booking.booking_type)
      formData.append('booking_status', this.booking.booking_status)
      formData.append('start_at', this.booking.start_at)
      formData.append('end_at', this.booking.end_at)
      formData.append('course_id', this.booking.course_id)
      formData.append('sales_ad_id', this.booking.sales_ad_id || 0)
      formData.append('is_honshi', this.booking.is_honshi)
      formData.append('place', this.booking.place)
      formData.append('note', this.booking.note)
      formData.append('booker_name', this.booking.booker_name)
      formData.append('booker_phone', ConvertPhone(this.booking.booker_phone))

      this.comeBack.booking = { ...this.booking }

      this.submitCallback(formData, this.comeBack)
    },
  }
}
</script>

<style scoped>
>>> .v-time-picker-title {
  justify-content: center;
}
</style>
