<template>
  <v-container fluid class="content-wrap">
    <!-- コンテンツヘッダー -->
    <info-bar
      :btns="[{label:'予約', icon:'plus-box-multiple', tip:'新規予約追加', event:'addClicked'}]"
      @addClicked="openFormRegister('create')"
    >
      <template v-slot:content-info>
        検索結果：{{ recordCount }}件 ／ 絞込み結果：{{ selectedCount }}件
      </template>
    </info-bar>

    <!-- フィルター -->
    <v-row no-gutters>
      <v-expansion-panels :value="0" popout>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <template>
              <v-row no-gutters justify="center">
                <v-col class="pt-1">
                  <v-fade-transition leave-absolute>
                    <span>{{ conditions }}</span>
                  </v-fade-transition>
                </v-col>
              </v-row>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- デートピッカー -->
            <v-row no-gutters align="center"
              :justify="$vuetify.breakpoint.smAndDown ? 'start' : 'end'"
            >
              <v-col cols="5" sm="4">
                <v-menu bottom
                  v-model="openPickerFrom"
                  :close-on-content-click="false"
                  transition="scroll-y-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="pickerDeteFrom"
                      label="検索開始"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="pickerDeteFrom"
                    locale="ja"
                    no-title
                    :day-format="date => numericDate(date)"
                    header-color="primary"
                    event-color="primary"
                    @input="openPickerFrom = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <span class="mx-2">から</span>
              <v-col cols="5" sm="4">
                <v-menu bottom
                  v-model="openPickerTo"
                  :close-on-content-click="false"
                  transition="scroll-y-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="pickerDeteTo"
                      label="検索終了"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="pickerDeteTo"
                    locale="ja"
                    no-title
                    :day-format="date => numericDate(date)"
                    header-color="primary"
                    event-color="primary"
                    @input="openPickerTo = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="2"
                class="ml-2 d-flex align-end justify-start"
                :class="$vuetify.breakpoint.xsOnly && 'mt-4'"
              >
                <v-btn
                  depressed small
                  color="primary"
                  @click="searchClicked()"
                >検索</v-btn>
                <btn-tip
                  class="ml-2"
                  tip="条件削除" icon="eraser"
                  text small
                  color="primary"
                  @click="clearCondition()"
                ></btn-tip>
              </v-col>
            </v-row>

            <!-- フィルター条件 -->
            <v-row no-gutters>
              <v-col cols="12" sm="6" md="3" class="px-2">
                <v-text-field
                  v-model.trim="whereCustomerId"
                  type="number" min=1
                  placeholder="会員番号"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3" class="px-2">
                <v-text-field
                  v-model.trim="whereBookerName"
                  placeholder="予約時の名前"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3" class="px-2">
                <v-text-field
                  v-model.trim="wherePhone"
                  placeholder="電話番号"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3" class="px-2">
                <v-select
                  class="mx-2"
                  v-model="whereCastName"
                  placeholder="キャスト"
                  hide-details
                  :items="casts"
                  item-value="name"
                  item-text="name"
                  item-color="primary"
                ></v-select>
                <!-- <v-text-field
                  v-model.trim="whereCastName"
                  placeholder="キャスト"
                  hide-details
                ></v-text-field> -->
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <!-- 予約情報カード -->
    <v-row>
      <v-col v-if="!bookings.length">
        <v-card flat>
          <v-card-text>
            <p>該当の予約情報がありません。<br />
            検索条件を変えて再検索してください。</p>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6" lg="4"
        v-for="(booking, index) in bookings"
        :key="booking.booking_id"
      >
        <v-card class="pb-4"
          :class="{
            confirmed: booking.booking_status === '確定',
            tentative: booking.booking_status === '仮予約',
            cancel: booking.booking_status === 'キャンセル'
          }"
        >
          <v-card-title class="py-3 align-end text-subtitle-1 font-weight-bold">
            <v-btn class="pa-0 mr-2 text-h6 font-weight-bold"
              text
              @click="whereCastName = booking.cast_name"
            >
              {{ booking.cast_name }}
            </v-btn>
            {{ bookingCourse(booking.course_name) }}
            {{ bookingStatus(booking.booking_status) }}
            {{ isHonshi(booking.is_honshi) }}
          </v-card-title>

          <!-- 顧客情報 -->
          <v-card-subtitle class="text-body-1 py-3">
            <v-row no-gutters>
              <v-col cols="8">
                <v-btn class="pa-0"
                  text
                  @click="whereBookerName = booking.booker_name"
                >
                  <span>お客様：</span>{{ displayName(booking) }}
                </v-btn>
              </v-col>
              <v-col cols="4" align="center">
                <v-btn class="py-0 px-2"
                  small outlined
                  color="primary"
                  v-if="booking.customer_id > 0 || booking.phone"
                  @click="routeToCustomerInfo(index)"
                >
                  顧客詳細
                  <v-icon small>mdi-open-in-new</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                  text class="pa-0"
                  @click="whereCustomerId = booking.customer_id"
                >
                  <span>会員番号：</span>{{ booking.customer_id == 0 ? '(未記入)' : zeroAddedNo(booking.customer_id) }}
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                  text class="pa-0"
                  @click="wherePhone = booking.phone"
                >
                  <span>電話：</span>{{ booking.phone || booking.booker_phone || '(未記入)' }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <!-- 予約情報 -->
          <v-card-text class="pb-0">
            <v-row no-gutters>
              <v-col cols="6">
                <span>日時：</span>{{ bookingDateTime(booking) }}
              </v-col>
              <v-col cols="6">
                <span>場所：</span>{{ booking.place }}
              </v-col>
              <v-col cols="12"
                class="mt-2"
                v-if="booking.note"
              >
                <span>備考：</span> {{ booking.note }}
              </v-col>
            </v-row>
          </v-card-text>

          <!-- スピードダイヤル -->
          <v-speed-dial class="mb-n2 mr-n2"
            v-model="booking.btn"
            absolute bottom right open-on-hover
            direction="left"
            transition="slide-x-reverse-transition"
          >
            <template v-slot:activator>
              <v-btn
                v-model="booking.btn"
                fab small
                color="primary"
              >
                <v-icon v-if="booking.btn">mdi-close</v-icon>
                <v-icon v-else>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <!-- ボタン -->
            <v-btn
              fab small
              color="primary"
              @click="openFormRegister('update', index)"
            >
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-btn>
            <v-btn
              fab small
              color="primary"
              @click="openModalDelete(index)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-speed-dial>

        </v-card>
      </v-col>
    </v-row>

    <!-- 登録フォーム -->
    <form-register
      ref="formRegister"
      :apiAdmin="apiAdmin"
      :shopData="shopData"
      :casts="casts"
      @reset="$emit('reset')"
    ></form-register>

    <!-- 削除モーダル -->
    <modal-delete ref="modalDelete">
      <div v-html="modalMessage"></div>
    </modal-delete>

    <!-- オーバーレイメッセージ -->
    <overlay-message ref="overlayMessage">
      <div v-html="modalMessage"></div>
    </overlay-message>

    <!-- スナックバー -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>

  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import moment from 'moment'
import 'moment/locale/ja'
import $literals from '@/literals.js'
import { ApiTool, CheckTokenError, BizHour } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import ContentInfoBar from '@/components/_ContentInfoBar.vue'
import BtnWithTip from '@/components/_BtnWithTip.vue'
import ModalDelete from '@/components/_ModalDelete.vue'
import OverlayMessage from '@/components/_OverlayMessage.vue'
import FormRegister from '@/components/BookingFormRegister.vue'

export default {
  components: {
    'loader': Loader,
    'info-bar': ContentInfoBar,
    'btn-tip': BtnWithTip,
    'form-register': FormRegister,
    'modal-delete': ModalDelete,
    'overlay-message': OverlayMessage,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  //***************************************************
  //データ
  //***************************************************
  data() {
    return {
      pickerDeteFrom: '',
      pickerDeteTo: '',
      openPickerFrom: false,
      openPickerTo: false,
      casts: [],
      bookings: [],
      bookingsSource: [],
      recordCount: 0,
      selectedCount: 0,
      whereCustomerId: '',
      whereBookerName: '',
      wherePhone: '',
      whereCastName: '',
      modalMessage: null,
      loading: false,
      loadingMessage: '',
      snackbar: {open: false, color: 'primary', message: ''},
      bizHour: new BizHour(this.shopData),
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
    }
  },

  //***************************************************
  //算出
  //***************************************************
  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
    bookingCourse() {
      return course =>
        course.indexOf('コース') !== -1 ? course : course + 'コース'
    },
    bookingDateTime() {
      return booking => {
        if (booking.course_id === 0) {
          return this.jpStart(booking.start_at) + '〜' + moment(booking.end_at).format('HH:mm')
        } else {
          return this.jpStart(booking.start_at)
        }
      }
    },
    bookingStatus() {
      return state => (state === '確定' ? '' : '(' + state + ')')
    },
    castName() {
      return castId => {
        const target = this.casts.find( cast => cast.cast_id == castId )
        return target !== undefined ? target.name : ''
      }
    },
    castId() {
      return castName => {
        const target = this.casts.find( cast => cast.name == castName )
        return target !== undefined ? target.cast_id : ''
      }
    },
    displayName() {
      return bookingData => bookingData.booker_name || bookingData.customer_name || '(名無し)'
    },
    zeroAddedNo() {
      return no => ('000' + no).slice(-4)
    },
    isHonshi() {
      return flag => (flag ? '🈯' : '')
    },
    jpStart() {
      return start => moment(start).format('M/D HH:mm')
    },
    numericDate() {
      return date => moment(date).format('D')
    },
    conditions() {
      let str = '検索条件：'
      if (this.pickerDeteFrom && this.pickerDeteTo) {
        str += moment(this.pickerDeteFrom).format('M月D日') + '〜' + moment(this.pickerDeteTo).format('M月D日')
      }
      if (this.whereCustomerId) str += (str.length > 5 ? ' ／ ' : '') + '会員番号＝' + this.whereCustomerId
      if (this.whereBookerName) str += (str.length > 5 ? ' ／ ' : '') + '予約名＝' + this.whereBookerName
      if (this.wherePhone) str +=      (str.length > 5 ? ' ／ ' : '') + '電話番号＝' + this.wherePhone
      if (this.whereCastName) str +=   (str.length > 5 ? ' ／ ' : '') + 'キャスト名＝' + this.whereCastName

      return str.length <= 5 ? '検索条件をひとつ以上入力してください。' : str
    }
  },

  //***************************************************
  //ウォッチ
  //***************************************************
  watch: {
    whereCustomerId: function(now) {
      if (!now || now.length === 0) {
        this.bookings = this.bookingsSource
      } else {
        this.bookings = this.bookingsSource.filter(booking => {
          return this.zeroAddedNo(booking.customer_id).indexOf(now) > -1
        })
      }
      this.selectedCount = this.bookings.length
    },
    whereBookerName: function(now) {
      if (!now || now.length === 0) {
        this.bookings = this.bookingsSource
      } else {
        this.bookings = this.bookingsSource.filter(booking => {
          return booking.booker_name.indexOf(now) > -1
        })
        this.selectedCount = this.bookings.length
      }
    },
    wherePhone: function(now) {
      if (!now || now.length <= 3) {
        this.bookings = this.bookingsSource
      } else if (now.length > 3) {
        this.bookings = this.bookingsSource.filter(booking => {
          return booking.phone.indexOf(now) > -1
        })
        this.selectedCount = this.bookings.length
      }
    },
    whereCastName: function(now) {
      if (!now || now.length === 0) {
        this.bookings = this.bookingsSource
      } else if (now.length >= 5) {
        this.bookings = this.bookingsSource.filter(booking => {
          return booking.cast_name === now
        })
        this.selectedCount = this.bookings.length
      } else if (now.length < 5) {
        this.bookings = this.bookingsSource.filter(booking => {
          return booking.cast_name.indexOf(now) > -1
        })
        this.selectedCount = this.bookings.length
      }
    }
  },

  //***************************************************
  //ルートガード
  //***************************************************
  beforeRouteUpdate(to, from, next) {
    if (this.$route.query.fromDate) this.pickerDeteFrom = to.query.fromDate
    if (this.$route.query.toDate) this.pickerDeteTo = to.query.toDate
    if (this.$route.query.customerId) this.whereCustomerId = to.query.customerId
    if (this.$route.query.bookerName) this.whereBookerName = to.query.bookerName
    if (this.$route.query.phone) this.wherePhone = to.query.phone
    if (this.$route.query.castId) this.whereCastName = this.castName(to.query.castId)

    //nextで this.$route の中身をtoへ更新してからAPIコール
    next()
    this.getBookingRecords()
  },

  //***************************************************
  //ライフサイクル
  //***************************************************
  mounted() {
    if (this.shopData.system_plan_id < 2) {
      this.modalMessage = $literals.MESSAGE.availableForPaidPlan
      this.$refs.overlayMessage.open()
    } else {
      this.adminApi.setToken(this.serverToken)

      this.snackbar = {...{color:'success', message: $literals.MESSAGE.infoSearchCondition, open: true}}

      this.adminApi.getReqWithAuth('cast/').then( results => {
        if (!results || !results.length) return
        this.casts = [...results]

        if ((!this.$route.query.fromDate || !this.$route.query.toDate) &&
          !this.$route.query.customerId && !this.$route.query.bookerName && !this.$route.query.phone && !this.$route.query.castId
        ) {
          this.pickerDeteFrom = moment(new Date()).format('YYYY-MM-DD')
          this.pickerDeteTo = moment(new Date()).format('YYYY-MM-DD')
        } else {
          if (this.$route.query.fromDate) this.pickerDeteFrom = this.$route.query.fromDate
          if (this.$route.query.toDate) this.pickerDeteTo = this.$route.query.toDate
          if (this.$route.query.customerId) this.whereCustomerId = this.$route.query.customerId
          if (this.$route.query.bookerName) this.whereBookerName = this.$route.query.bookerName
          if (this.$route.query.phone) this.wherePhone = this.$route.query.phone
          if (this.$route.query.castId) this.whereCastName = this.castName(this.$route.query.castId)

          this.getBookingRecords()
        }
      })
    }
  },

  //***************************************************
  //メソッド
  //***************************************************
  methods: {
    //検索ボタンクリック
    searchClicked() {
      if ((!this.pickerDeteFrom || !this.pickerDeteTo) &&
        !this.whereCustomerId && !this.whereBookerName && !this.wherePhone && !this.whereCastName
      ) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.warningNoSearchCondition, open: true}}
        return
      }

      if (
        this.$route.query.fromDate !== this.pickerDeteFrom ||
        this.$route.query.toDate !== this.pickerDeteTo ||
        this.$route.query.customerId != this.whereCustomerId ||
        this.$route.query.bookerName !== this.whereBookerName ||
        this.$route.query.phone !== this.wherePhone ||
        this.$route.query.castId != this.castId(this.whereCastName)
      ) {
        this.$router.push({
          name: this.$route.name,
          params: { title: this.$route.title },
          query: {
            fromDate: this.pickerDeteFrom,
            toDate: this.pickerDeteTo,
            customerId: this.whereCustomerId,
            bookerName: this.whereBookerName,
            phone: this.wherePhone,
            castId: this.castId(this.whereCastName)
          }
        })
      }
    },

    //条件クリア
    clearCondition() {
      this.pickerDeteFrom = ''
      this.pickerDeteTo = ''
      this.whereCustomerId = ''
      this.whereBookerName = ''
      this.wherePhone = ''
      this.whereCastName = ''
    },

    //予約種類で表示をトグル
    toggleDisplayStatus(bookingType) {
      const els = document.querySelectorAll('.' + bookingType)
      for (let el of els) {
        el.style.display =
          el.style.display === 'none' ? 'inline-block' : 'none'
        // this.selectedCount += el.style.display === 'inline-block' ? 1 : -1
      }
    },

    //顧客詳細クリック
    routeToCustomerInfo(index) {
      this.$router.push({
        name: 'shopAdminCustomer',
        params: { title: 'お客様台帳' },
        query: { customer_id: this.bookings[index].customer_id }
      })
    },

    //新規登録クリック
    openFormRegister(type, index) {
      const callback = type === 'create' ? this.createBooking : this.updateBooking

      const formHanddown = {
        formType: type,
        updateData: this.bookings[index],
        submitCallback: callback,
        comeBack: { index: index }
      }
      this.$refs.formRegister.open(formHanddown)
    },

    //削除クリック
    openModalDelete(index) {
      this.modalMessage = "<p class='mb-4'>以下の情報を削除してよろしいですか？</p>" +
        '<p>キャスト：' + this.bookings[index].cast_name + '</p>' +
        '<p>日時：' + this.jpStart(this.bookings[index].start_at) + 'から' + this.bookings[index].course_name + '</p>'

      const modalHanddown = {
        submitCallback: this.deleteBooking,
        comeBack: { index: index }
      }
      this.$refs.modalDelete.open(modalHanddown)
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    //予約データ取得
    //**************************************************
    getBookingRecords() {
      if (moment(this.pickerDeteFrom).isAfter(this.pickerDeteTo)) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.warningInvalidDateRange, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = '予約データ取得中・・・'

      const query = {
        fromDate: this.bizHour.getBizOpening(this.$route.query.fromDate +' '+ this.bizHour.openingHour),
        toDate: this.bizHour.getBizClosing(this.$route.query.toDate +' '+ this.bizHour.openingHour),
        customerId: this.$route.query.customerId,
        bookerName: this.$route.query.bookerName,
        phone: this.$route.query.phone,
        castId: this.$route.query.castId
      }

      this.adminApi.getReqWithAuth('booking/', query).then( results => {
        this.bookings.length = 0
        this.bookingsSource.length = 0

        if (!results || !results.length) return

        results.map( row => {
          row.btn = false
          this.bookings.push(row)
        })
        this.bookingsSource = results
        this.recordCount = this.selectedCount = this.selectedCount = this.bookings.length
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //予約データ新規登録
    //**************************************************
    createBooking(formData, cameBackData) {
      this.adminApi.apiReqWithData('POST', 'booking/create/', formData).then( response => {
        cameBackData.booking.booking_id = response.id
        this.bookings.unshift(cameBackData.booking)

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.$refs.formRegister.close() )
    },

    //**************************************************
    //予約データ更新
    //**************************************************
    updateBooking(formData, cameBackData) {
      const apiPartial = 'booking/update/' + this.bookings[cameBackData.index].booking_id

      this.adminApi.apiReqWithData('PUT', apiPartial, formData).then(() => {
        this.bookings.splice(cameBackData.index, 1, cameBackData.booking)
        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.$refs.formRegister.close() )
    },

    //**************************************************
    //予約データ削除
    //**************************************************
    deleteBooking(cameBackData) {
      const apiPartial = 'booking/delete/' + this.bookings[cameBackData.index].booking_id

      this.adminApi.apiReqWithData('DELETE', apiPartial).then(() => {
        this.bookings.splice(cameBackData.index, 1)
        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.$refs.modalDelete.close() )
    }
  }
}
</script>

<style scoped>
.v-expansion-panel-header {
  height: 40px !important;
  min-height: 40px !important;
}
.v-card__subtitle .v-btn:not(.v-size--small) {
  height: 30px;
}
</style>
